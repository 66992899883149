import React, { useState } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { useHotkeys } from 'react-hotkeys-hook';

import { css, styled, ThemeProvider, thd, up } from '@smooth-ui/core-sc';

import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { getFormInputs } from 'final-form-focus';
import history from '_platform/src/utils/history';
import { withSettings } from 'containers/WebApp/SettingsContext';
import FormSelect from '_platform/src/components/FormSelect/FormSelect';

const SpotlightContainer = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 100%;
  width: 240px;
  z-index: 10;

  &.has-focus {
  }

  form {
    margin: 0 0.5rem;
    width: 100%;
  }

  ${up(
    'sm',
    css`
      width: 320px;

      .sui-form-group {
        margin-bottom: 0;
      }
    `
  )}

  label {
    display: none;
  }

  .rs__placeholder {
    color: ${thd('spotlightPlaceholder', '#fff')};
  }

  code {
    background-color: ${thd(
      'spotlightShortcutBackground',
      'rgba(0, 0, 0, 0.1)'
    )};
    border: 1px solid
      ${thd('spotlightShortcutBorder', 'rgba(255, 255, 255, 0.25)')};
    color: ${thd('spotlightShortcutColor', '#fff')};
    margin-left: 0.5em;
  }

  /* Style the dropdown */
  .rs__menu {
    background-color: ${thd('spotlightDropdownBackground', '#444')};
  }

  .rs__option {
    &:active {
      background-color: ${thd(
        'spotlightDropdownActive',
        'rgba(255, 255, 255, 0.4)'
      )};
    }
  }

  .rs__option--is-focused {
    background-color: ${thd(
      'spotlightDropdownHover',
      'rgba(255, 255, 255, 0.25)'
    )};
    &:active,
    &.rs__option--is-selected {
      background-color: ${thd(
        'spotlightDropdownActive',
        'rgba(255, 255, 255, 0.4)'
      )};
    }
  }

  .rs__option--is-selected {
    background-color: ${thd(
      'spotlightDropdownActive',
      'rgba(255, 255, 255, 0.3)'
    )};
  }
`;

const getPages = memoizeOne(routes => {
  if (!routes) return undefined;

  const filteredRoutes = routes.filter(
    route => route.menuPath && !route.menuPath.includes(':')
  );

  return (
    filteredRoutes &&
    filteredRoutes.map(route => ({
      value: route.menuPath,
      label: route.parentId ? ` - ${route.itemName}` : route.itemName,
    }))
  );
});

const onSubmit = values => values.spotlight && history.push(values.spotlight);

const Spotlight = props => {
  const [focused, setFocused] = useState(false);
  useHotkeys('ctrl+/', () => {
    const spotlightInput = getFormInputs('spotlight-form')();

    if (spotlightInput && spotlightInput[0]) {
      spotlightInput[0].focus();
    }
  });

  return (
    <SpotlightContainer
      className={`spotlight-container${focused ? ' has-focus' : ''}`}
    >
      <ThemeProvider
        theme={{
          inputBgColor: thd('spotlightBgColor', '#333'),
          inputBorderColor: thd('spotlightBorderColor', '#444'),
          primary: thd('spotlightSuccess', '#666'), // Override success border colour
          inputTextColor: thd('spotlightTextColor', '#bbb'),
        }}
      >
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} name="spotlight-form">
              <Field
                name="spotlight"
                component={FormSelect}
                options={getPages(props.settings.routes)}
                label="Jump to page"
                placeholder={
                  <React.Fragment>
                    Jump to page <code>ctrl+/</code>
                  </React.Fragment>
                }
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
              <OnChange name="spotlight">{handleSubmit}</OnChange>
            </form>
          )}
        />
      </ThemeProvider>
    </SpotlightContainer>
  );
};

Spotlight.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(Spotlight);
