/**
 * FormSelect
 */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  ControlFeedback,
  controlFocus,
  css,
  danger,
  FormGroup,
  primary,
  success,
  styled,
  th,
} from '@smooth-ui/core-sc';
import FormHelpText from '../../components/FormHelpText/FormHelpText'; // _platform
import FormHint from '../../components/FormHint/FormHint'; // _platform
import FormLabel from '../../components/FormLabel/FormLabel'; // _platform
import FormPlaceholder from '../../components/FormPlaceholder/FormPlaceholder'; // _platform

const StyledSelect = styled(Select)`
  .rs__control {
    background-color: ${th('inputBgColor')};
    border-width: ${th('inputBorderWidth')};
    border-color: ${th('inputBorderColor')};
    border-style: solid;
    border-radius: ${th('borderRadius')};
    color: ${th('inputTextColor')};
    padding: 0;
  }

  .rs__control:hover {
    border-color: ${th('primary')};
  }

  &.rs-invalid .rs__control {
    border-color: ${th('danger')};
  }

  .rs__control--is-disabled {
    background-color: ${th('inputDisabledBgColor')};
    color: ${th('inputDisabledText')};
    cursor: default;
  }

  .rs__control--is-focused:not(.rs__control--is-disabled) {
    ${p => controlFocus(primary(p))(p)}
    border-color: ${p => primary(p)};

    input:focus {
      box-shadow: none;
    }
  }

  &.rs-invalid .rs__control--is-focused {
    ${p => controlFocus(danger(p))(p)}
    border-color: ${p => danger(p)};
  }

  .rs__placeholder {
    color: ${th('inputPlaceholderText')};
    opacity: 0.54;
  }

  &:not(.rs--is-disabled) {
    .rs__input,
    .rs__single-value,
    .rs__menu {
      background-color: ${th('inputBgColor')};
      border-radius: ${th('borderRadius')};
      color: ${th('inputTextColor')};
    }
  }

  .rs__menu {
    z-index: 20;
  }

  /* Match padding to other inputs */
  .rs__value-container {
    padding: 0.125rem 0.5rem;

    .rs__single-value {
      margin-left: 0.25rem;
    }
  }

  ${p =>
    p.meta &&
    p.meta.touched &&
    p.meta.valid &&
    css`
      .rs__control {
        border-color: ${success(p)} !important;
      }
      .rs__control--is-focused:not(.rs__control--is-disabled) {
        ${p => controlFocus(success(p))(p)}
      }
    `}
`;

const FormSelect = ({
  input,
  meta,
  immutable,
  helpText,
  label,
  hint,
  isRequiredIndicator,
  options,
  selectIfOnlyOption,
  ...rest
}) => {
  // Select if only option - Auto-select the option if there is only one
  // Use only on required fields, as it forces the value
  if (selectIfOnlyOption && !input.value && options && options.length === 1) {
    if (rest.multi) {
      input.onChange([options[0].value]);
    } else {
      input.onChange(options[0].value);
    }
  }

  let currentValue = null;

  if (input.value && options && options.length > 0) {
    if (!Array.isArray(input.value)) {
      currentValue = options.find(option => option.value === input.value);
    } else {
      currentValue = options.filter(
        item => input.value.indexOf(item.value) !== -1
      );
    }
  }

  if (process.env.NODE_ENV === 'development' && !label) {
    // eslint-disable-next-line no-console
    console.warn(
      `Dev: FormSelect '${input && input.name}' does not have label specified`
    );
  }

  return (
    <FormGroup>
      {helpText && <FormHelpText htmlValue={helpText} />}
      {label ? (
        <FormLabel
          htmlFor={input.name}
          isRequiredIndicator={isRequiredIndicator}
        >
          {label}
        </FormLabel>
      ) : null}
      {hint ? <FormHint>{hint}</FormHint> : null}
      {immutable && (
        <FormPlaceholder>{currentValue && currentValue.label}</FormPlaceholder>
      )}
      {!immutable && (
        <StyledSelect
          {...input}
          meta={meta}
          label={label}
          options={options || []}
          className={
            meta.touched && !meta.valid
              ? 'react-select rs-invalid'
              : 'react-select'
          }
          classNamePrefix="rs"
          isClearable={!(selectIfOnlyOption && options && options.length === 1)}
          isDisabled={rest.disabled || !options || options.length === 0}
          isMulti={rest.multi && options && options.length > 0}
          // value and onChange are needed in order to normalise the values with
          // what is expected at the API endpoints
          // https://github.com/JedWatson/react-select/issues/2920
          value={currentValue}
          onChange={option => {
            if (option) {
              if (Array.isArray(option)) {
                input.onChange(option.map(o => o.value));
              } else {
                input.onChange(option.value);
              }
            } else {
              input.onChange(null);
            }
          }}
          {...rest}
        />
      )}
      {meta.touched && !meta.valid ? (
        <ControlFeedback valid={meta.valid}>{meta.error}</ControlFeedback>
      ) : null}
    </FormGroup>
  );
};

FormSelect.propTypes = {
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  immutable: PropTypes.bool,
  isRequiredIndicator: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  meta: PropTypes.object.isRequired,
  options: PropTypes.array,
  selectIfOnlyOption: PropTypes.bool,
};

FormSelect.defaultProps = {
  helpText: undefined,
  hint: '',
  immutable: false,
  isRequiredIndicator: undefined,
  label: undefined,
  options: undefined,
  selectIfOnlyOption: false,
};

export default FormSelect;
